import clsx from 'clsx';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import { OperationApprovalFilterRequest } from 'model/operation-approvals';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import SimpleTextNumberInput from 'components/inputs/text-number-input/simple-text-number-input';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './operation-approval-modal-filter.scss';

const I18N_PREFIX = 'pages.scd.ccb-operation-approval';

export type OperationApprovalModalFilterType = 'status' | 'filters' | 'ccbNumber' | 'debtorSearch';

export interface OperationApprovalModalFilterProps {
    onClose: () => void;
    modalFilter: OperationApprovalModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: OperationApprovalFilterRequest;
    setFilterActive: (value: OperationApprovalFilterRequest) => void;
    setPage: (value: number) => void;
}

export const DISABLED_OP_APPR_MODAL_BUTTON_FUNCTION_RECORD = {
    filters: filter => {
        const isPendingStatus = filter?.status?.some(status => status === OperationApprovalStatus.PENDING);

        const filteredObject = isPendingStatus ? { ...filter, status: undefined } : { ...filter, status: filter?.status?.length ? filter.status : undefined };

        return valuesOfObject(filteredObject).filter(it => it !== undefined).length === 0;
    },
    status: filter => !filter.status?.length,
    ccbNumber: filter => !filter.ccbNumber,
    debtorSearch: filter => !filter.debtorSearch,
} as Record<OperationApprovalModalFilterType, (filter: OperationApprovalFilterRequest) => boolean>;

export const OperationApprovalModalFilter = (props: OperationApprovalModalFilterProps) => {
    const { filterActive, modalFilter, onClose, openModal, setFilterActive, setPage, title } = props;

    const { t } = useTranslation();

    const [filterRequest, setFilterRequest] = useState<OperationApprovalFilterRequest>(filterActive);

    useEffect(() => {
        if (filterActive) {
            setFilterRequest(filterActive);
        }
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<OperationApprovalFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleChangeStatus = (operationStatus: OperationApprovalStatus) => {
        const hasSelectedStatus = filterRequest?.status?.some(status => status === operationStatus);

        if (hasSelectedStatus) {
            const filteredData = filterRequest?.status?.filter(status => status !== operationStatus);
            handleChange({ status: filteredData });
            return;
        }
        handleChange({ status: filterRequest?.status?.concat(operationStatus) });
    };

    const handleClearFilter = {
        filters: () => {
            setFilterActive({ status: [OperationApprovalStatus.APPROVED, OperationApprovalStatus.DISAPPROVED] });
            setFilterRequest({ status: [OperationApprovalStatus.APPROVED, OperationApprovalStatus.DISAPPROVED] });
        },
        status: () => {
            setFilterActive({ ...filterActive, status: [OperationApprovalStatus.APPROVED, OperationApprovalStatus.DISAPPROVED] });
            setFilterRequest({ ...filterRequest, status: [OperationApprovalStatus.APPROVED, OperationApprovalStatus.DISAPPROVED] });
        },
        ccbNumber: () => {
            setFilterActive({ ...filterActive, ccbNumber: undefined });
            setFilterRequest({ ...filterRequest, ccbNumber: undefined });
        },
        debtorSearch: () => {
            setFilterActive({ ...filterActive, debtorSearch: undefined });
            setFilterRequest({ ...filterRequest, debtorSearch: undefined });
        },
    } as Record<OperationApprovalModalFilterType, () => void>;

    const CcbNumberFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextNumberInput
                    onChange={ccbNumber => handleChange({ ccbNumber })}
                    label={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                    value={filterRequest?.ccbNumber ?? ''}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const StatusFilter: JSX.Element = (
        <>
            <div
                className={clsx('operation-approval-modal-filter--container-status--label', {
                    APPROVED: filterRequest?.status?.some(status => status === OperationApprovalStatus.APPROVED),
                })}
                onClick={() => handleChangeStatus(OperationApprovalStatus.APPROVED)}
            >
                {t(`${I18N_PREFIX}.status.options.APPROVED`).toUpperCase()}
            </div>
            <div
                className={clsx('operation-approval-modal-filter--container-status--label', {
                    DISAPPROVED: filterRequest?.status?.some(status => status === OperationApprovalStatus.DISAPPROVED),
                })}
                onClick={() => handleChangeStatus(OperationApprovalStatus.DISAPPROVED)}
            >
                {t(`${I18N_PREFIX}.status.options.DISAPPROVED`).toUpperCase()}
            </div>
        </>
    );

    const DebtorSearchFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput
                    onChange={debtorSearch => handleChange({ debtorSearch })}
                    label={t(`${I18N_PREFIX}.inputs.debtorSearch`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.debtorSearch`)}
                    value={filterRequest?.debtorSearch ?? ''}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const isConfirmButtonDisabled = DISABLED_OP_APPR_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_OP_APPR_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);
    const isPendingStatus = filterRequest?.status?.some(status => status === OperationApprovalStatus.PENDING);

    return (
        openModal && (
            <Modal isOpen>
                <div className="operation-approval-modal-filter--container">
                    <div className="operation-approval-modal-filter--header">
                        <label className="operation-approval-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {isPendingStatus ? (
                                                <>
                                                    {CcbNumberFilter}
                                                    {DebtorSearchFilter}
                                                </>
                                            ) : (
                                                <>
                                                    {CcbNumberFilter}
                                                    {StatusFilter}
                                                    {DebtorSearchFilter}
                                                </>
                                            )}
                                        </>
                                    ),
                                    status: StatusFilter,
                                    ccbNumber: CcbNumberFilter,
                                    debtorSearch: DebtorSearchFilter,
                                } as Record<OperationApprovalModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="operation-approval-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButton label="entity.action.confirm" onClick={handleConfirmRequest} disabled={isConfirmButtonDisabled} />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default OperationApprovalModalFilter;
