import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import RefreshIcon from '@material-ui/icons/Refresh';
import clsx from 'clsx';
import BackDrop from 'components/backdrop/backdrop';
import BackButton from 'components/buttons/back-button/back-button';
import SwitchPendingHistoryButton from 'components/buttons/switch-pending-history-button/switch-pending-history-button';
import SimpleTextInput from 'components/inputs/text-input/text-input';
import Loading from 'components/loading/loading';
import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import { DisapproveOperationApprovalRequest, OperationApproval, OperationApprovalFilterPageable, OperationApprovalFilterRequest, defaultOperationApprovalFilter } from 'model/operation-approvals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useApproveMultipleOperationsApprovalState, useApproveOperationApprovalState, useDisapproveOperationApprovalState, useListOperationApprovalsState, useRootDispatch } from 'reducer/hooks';
import { approveMultiplesOperationsApprovalRequest, approveMultiplesOperationsApprovalResetState } from 'reducer/operation-approval/approve-multiple-operations/actions';
import { approveOperationApprovalRequest, approveOperationApprovalResetState } from 'reducer/operation-approval/approve-operation/actions';
import { disapproveOperationApprovalRequest, disapproveOperationApprovalResetState } from 'reducer/operation-approval/disapprove-operation/actions';
import { listOperationApprovalsRequest, listOperationApprovalsResetState } from 'reducer/operation-approval/list-operation-approvals/actions';
import { Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';
import Pagination from 'shared/pagination/pagination';
import './ccb-operation-approval.scss';
import { OperationApprovalMoreOptionsType } from './components/more-options-icon-button/menu/operation-approval-more-options-menu';
import OperationApprovalFilterButtons from './components/operation-approval-filter-buttons/operation-approval-filter-buttons';
import ScdOperationApprovalHeader, { OperationApprovalsDataSortable } from './components/operation-approval-header/operation-approval-header';
import ScdOperationApprovalList from './components/operation-approval-list/operation-approval-list';
import OperationApprovalModalError from './components/operation-approval-modal-error/operation-approval-modal-error';
import OperationApprovalModalFilter, { OperationApprovalModalFilterType } from './components/operation-approval-modal-filter/operation-approval-modal-filter';
import OperationApprovalSlide from './components/operation-approval-slide/operation-approval-slide';

const I18N_PREFIX = 'pages.scd.ccb-operation-approval';

const ScdOperationApproval = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();
    const { toastSuccess, toastInfo, closeToast } = useToast();

    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [filterActive, setFilterActive] = useState<OperationApprovalFilterRequest>(defaultOperationApprovalFilter);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);
    const [modalFilter, setModalFilter] = useState<OperationApprovalModalFilterType | undefined>(undefined);
    const [selectedOperationApproval, setSelectedOperationApproval] = useState<OperationApproval | undefined>(undefined);
    const [modalType, setModalType] = useState<OperationApprovalMoreOptionsType | undefined>(undefined);
    const [operationToDisapprove, setOperationToDisapprove] = useState<DisapproveOperationApprovalRequest | undefined>(undefined);
    const [operationApprovalsIds, setOperationApprovalsIds] = useState<number[]>([]);
    const [showCheckbox, setShowCheckbox] = useState<boolean>(false);
    const [isSelectedAllCheckbox, setIsSelectedAllCheckbox] = useState<boolean>(false);
    const [isReasonInvalid, setIsReasonInvalid] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { operationApprovals, status: operationsListStatus } = useListOperationApprovalsState();
    const { status: approveOperationStatus } = useApproveOperationApprovalState();
    const { status: disapproveOperationStatus } = useDisapproveOperationApprovalState();
    const { status: approveMultiplesOperationsStatus, errorResponse } = useApproveMultipleOperationsApprovalState();

    const filterPageable = useCallback((): OperationApprovalFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: 8,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const _filters = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filters,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handleListOperationApprovals = useCallback(() => {
        dispatch(listOperationApprovalsRequest(filterPageable()));
    }, [dispatch, filterPageable]);

    const onRequestSuccess = useCallback(() => {
        toastSuccess(t(`${I18N_PREFIX}.toast.approveMultipleOperations.success`));
        setOperationApprovalsIds([]);
        setShowCheckbox(false);
        setIsSelectedAllCheckbox(false);
        setModalType(undefined);
        handleListOperationApprovals();
    }, [handleListOperationApprovals, t, toastSuccess]);

    const onRequestSuccessWithErrors = useCallback(() => {
        setOperationApprovalsIds([]);
        setShowCheckbox(false);
        setIsSelectedAllCheckbox(false);
        setModalType(undefined);
    }, []);

    useEffect(() => {
        handleListOperationApprovals();
    }, [handleListOperationApprovals]);

    useEffect(() => {
        return () => {
            dispatch(listOperationApprovalsResetState());
            dispatch(approveOperationApprovalResetState());
            dispatch(disapproveOperationApprovalResetState());
            dispatch(approveMultiplesOperationsApprovalResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (approveOperationStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.approveOperation.success`));
        dispatch(approveOperationApprovalResetState());
        handleListOperationApprovals();
        setModalType(undefined);
    }, [approveOperationStatus, toastSuccess, handleListOperationApprovals, t, dispatch]);

    useEffect(() => {
        if (approveOperationStatus !== HttpRequestStatus.ERROR) return;

        dispatch(approveOperationApprovalResetState());
        setModalType(undefined);
    }, [dispatch, approveOperationStatus]);

    useEffect(() => {
        if (disapproveOperationStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.disapproveOperation.success`));
        dispatch(disapproveOperationApprovalResetState());
        handleListOperationApprovals();
        setModalType(undefined);
        setOperationToDisapprove(undefined);
    }, [disapproveOperationStatus, toastSuccess, dispatch, handleListOperationApprovals, t]);

    useEffect(() => {
        if (disapproveOperationStatus !== HttpRequestStatus.ERROR) return;

        dispatch(disapproveOperationApprovalResetState());
        setModalType(undefined);
        setOperationToDisapprove(undefined);
    }, [disapproveOperationStatus, dispatch]);

    useEffect(() => {
        if (approveMultiplesOperationsStatus !== HttpRequestStatus.ERROR) return;

        dispatch(approveMultiplesOperationsApprovalResetState());
        setModalType(undefined);
        setOperationApprovalsIds([]);
    }, [approveMultiplesOperationsStatus, dispatch]);

    useEffect(() => {
        if (approveMultiplesOperationsStatus !== HttpRequestStatus.SUCCESS) return;

        if (errorResponse?.errors.length) {
            closeToast();
            onRequestSuccessWithErrors();
            return;
        }

        closeToast();
        onRequestSuccess();
        dispatch(approveMultiplesOperationsApprovalResetState());
    }, [dispatch, approveMultiplesOperationsStatus, closeToast, errorResponse, onRequestSuccess, onRequestSuccessWithErrors]);

    const hasError = operationsListStatus === HttpRequestStatus.ERROR;

    const isLoading = operationsListStatus !== HttpRequestStatus.SUCCESS;

    const isShowingPendingOperations: boolean = !!filterActive?.status?.includes(OperationApprovalStatus.PENDING);

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleResetFilterActive = () => {
        if (isShowingPendingOperations) {
            setFilterActive({ status: [OperationApprovalStatus.PENDING] });
        } else {
            setFilterActive({ status: [OperationApprovalStatus.APPROVED, OperationApprovalStatus.DISAPPROVED] });
        }
        setPage(0);
    };

    const isDefaultPending = filterActive?.status?.includes(OperationApprovalStatus.PENDING);

    const handleSort = (property: OperationApprovalsDataSortable, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleSwitch = (value: OperationApprovalStatus[]) => {
        setPage(0);
        setFilterActive({ status: value });
        setShowCheckbox(false);
        setIsSelectedAllCheckbox(false);
        setOperationApprovalsIds([]);
        setModalType(undefined);
    };

    const handleChangeInputModal = (value: Partial<DisapproveOperationApprovalRequest>) => {
        setOperationToDisapprove({ ...operationToDisapprove, ...value });
    };

    const handleMoreOptionsMenuAction = {
        viewCcb: () => {
            history.push(`/imported-operations/view-ccb/${selectedOperationApproval?.importedOperationId}`);
        },
        approveOperation: () => {
            dispatch(approveOperationApprovalRequest(Number(selectedOperationApproval?.id)));
        },
        disapproveOperation: () => {
            if (!operationToDisapprove?.id) return;
            const disapprovalReason = operationToDisapprove?.disapprovalReason?.trim();
            if (!disapprovalReason) {
                setErrorMessage(t(`${I18N_PREFIX}.modal.more-options.disapproveOperation.input.error.required`));
                setIsReasonInvalid(true);
                return;
            }
            if (disapprovalReason.length < 3) {
                setErrorMessage(t(`${I18N_PREFIX}.modal.more-options.disapproveOperation.input.error.minChar`));
                setIsReasonInvalid(true);
                return;
            }
            if (disapprovalReason.length > 255) {
                setErrorMessage(t(`${I18N_PREFIX}.modal.more-options.disapproveOperation.input.error.maxChar`));
                setIsReasonInvalid(true);
                return;
            }

            setIsReasonInvalid(false);
            dispatch(disapproveOperationApprovalRequest(operationToDisapprove as DisapproveOperationApprovalRequest));
        },
        cancel: () => {
            setOperationApprovalsIds([]);
            setShowCheckbox(false);
            setModalType(undefined);
        },
        approveMultiplesOperations: () => {
            if (!operationApprovalsIds.length) return;

            dispatch(approveMultiplesOperationsApprovalRequest({ operationApprovalsIds }));
        },
    } as Record<OperationApprovalMoreOptionsType, () => void>;

    const handleShowCheckbox = () => {
        toastInfo(t(`${I18N_PREFIX}.toast.approveMultipleOperations.info`), <LibraryAddCheckIcon fontSize="large" style={{ marginRight: '10px' }} />);
        setOperationApprovalsIds([]);
        setShowCheckbox(true);
    };

    const filteredIds = useMemo((): number[] | undefined => {
        return operationApprovals?.content?.map(it => it.id);
    }, [operationApprovals]);

    const handleSelectAllCheckbox = () => {
        if (!filteredIds) return;

        if (!isSelectedAllCheckbox) {
            setIsSelectedAllCheckbox(true);
            setOperationApprovalsIds(filteredIds);
            return;
        }
        setIsSelectedAllCheckbox(false);
        setOperationApprovalsIds([]);
    };

    const validateCheckbox = useMemo((): boolean => {
        if (!operationApprovals?.content?.length) {
            return false;
        }

        return operationApprovals?.content?.length === operationApprovalsIds.length;
    }, [operationApprovals, operationApprovalsIds]);

    useEffect(() => {
        setIsSelectedAllCheckbox(validateCheckbox);
    }, [validateCheckbox]);

    useEffect(() => {
        if (!isSelectedAllCheckbox) return;
        if (!isDefaultPending) return;

        if (!filteredIds) return;

        setOperationApprovalsIds(filteredIds);
    }, [isSelectedAllCheckbox, operationApprovals, isDefaultPending, filteredIds]);

    const hasSelectedOperation = useCallback((id: number): boolean => operationApprovalsIds?.some(operationId => operationId === id), [operationApprovalsIds]);

    const handleChange = (id: number) => {
        if (isSelectedAllCheckbox) {
            setIsSelectedAllCheckbox(false);
        }

        if (hasSelectedOperation(id)) {
            const filteredData: number[] = operationApprovalsIds.filter(operationId => operationId !== id);

            setOperationApprovalsIds(filteredData);
            return;
        }

        setOperationApprovalsIds([...operationApprovalsIds, id]);
    };

    const verifyCheckedCheckbox = useCallback(
        (id: number): boolean => {
            if (!showCheckbox) return false;

            if (isSelectedAllCheckbox) return isSelectedAllCheckbox;

            return hasSelectedOperation(id);
        },
        [isSelectedAllCheckbox, hasSelectedOperation, showCheckbox]
    );

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('pages.scd.ccb-operation.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleListOperationApprovals} />
            </div>
        );
    }

    const isRequestLoading = approveOperationStatus === HttpRequestStatus.ON_GOING || disapproveOperationStatus === HttpRequestStatus.ON_GOING || approveMultiplesOperationsStatus === HttpRequestStatus.ON_GOING;

    return (
        <>
            {isRequestLoading && <BackDrop open={isRequestLoading} />}
            <main className="scd-ccb-operation-approval">
                <section className="scd-ccb-operation-approval__container">
                    <header className="scd-ccb-operation-approval__header">
                        <div className="scd-ccb-operation-approval__header-container">
                            <div className="scd-ccb-operation-approval__header-container__icon-container">
                                <h2 className="scd-ccb-operation-approval__header-container--title">{t(`${I18N_PREFIX}.title`)}</h2>
                                <RefreshIcon className="scd-ccb-operation-approval__header-container--refresh-icon" onClick={handleListOperationApprovals} />
                            </div>
                            {isShowingPendingOperations && !showCheckbox && (
                                <div className="scd-ccb-operation-approval__header--select-operations" onClick={handleShowCheckbox}>
                                    <span className="scd-ccb-operation-approval__header--select-operations__icon" />
                                    <span className="scd-ccb-operation-approval__header--select-operations__value">{t('entity.action.select').toLocaleUpperCase()}</span>
                                </div>
                            )}
                            <div className="scd-ccb-operation-approval__header-container--align-right">
                                <SwitchPendingHistoryButton onSwitch={handleSwitch} isDefaultPending={isDefaultPending} />
                            </div>
                        </div>
                        <div className="scd-ccb-operation-approval__header--filter-content">
                            <div className="scd-ccb-operation-approval__header--buttons-content">
                                <OperationApprovalFilterButtons filterActive={filterActive} setOpenModalFilter={setOpenModalFilter} handleResetFilterActive={handleResetFilterActive} setModalFilter={setModalFilter} />
                            </div>
                        </div>
                    </header>
                    <div className="scd-ccb-operation-approval__table">
                        <table
                            className={clsx('page-container--table', {
                                PENDING: isDefaultPending,
                                OTHERS: !isDefaultPending,
                            })}
                        >
                            <thead>
                                <ScdOperationApprovalHeader
                                    operationApprovals={operationApprovals?.content ?? []}
                                    handleSort={handleSort}
                                    sortOrder={sortOrder}
                                    sortedProperty={sortedProperty}
                                    isPending={isDefaultPending}
                                    showCheckbox={showCheckbox}
                                    handleSelectAllCheckbox={handleSelectAllCheckbox}
                                    isSelectedAllCheckbox={isSelectedAllCheckbox}
                                />
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <Loading isTable />
                                ) : (
                                    <ScdOperationApprovalList
                                        filterActive={filterActive}
                                        isPending={isDefaultPending}
                                        setSelectedOperationApproval={setSelectedOperationApproval}
                                        setModalType={setModalType}
                                        setOperationToDisapprove={setOperationToDisapprove}
                                        showCheckbox={showCheckbox}
                                        handleChange={handleChange}
                                        verifyCheckedCheckbox={verifyCheckedCheckbox}
                                    />
                                )}
                            </tbody>
                        </table>
                    </div>
                    {showCheckbox && (
                        <OperationApprovalSlide onCancel={operationApprovalsIds.length === 0 ? () => handleMoreOptionsMenuAction.cancel() : undefined} operationApprovalsIds={operationApprovalsIds} setModalType={setModalType} />
                    )}
                    {!isLoading && <Pagination page={page} totalPages={operationApprovals?.totalPages ?? 0} onChange={handlePageChange} />}
                    {modalFilter && (
                        <OperationApprovalModalFilter
                            openModal={openModalFilter}
                            modalFilter={modalFilter}
                            onClose={() => setOpenModalFilter(false)}
                            title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                            setFilterActive={setFilterActive}
                            filterActive={filterActive}
                            setPage={setPage}
                        />
                    )}
                </section>
                {modalType && (
                    <ModalMessage
                        title={t(`${I18N_PREFIX}.modal.more-options.${modalType}.title`)}
                        message={t(`${I18N_PREFIX}.modal.more-options.${modalType}.message`)}
                        onCancel={() => {
                            setModalType(undefined);
                            setOperationToDisapprove(undefined);
                            setIsReasonInvalid(false);
                        }}
                        onClose={() => {
                            setModalType(undefined);
                            setOperationToDisapprove(undefined);
                            setIsReasonInvalid(false);
                        }}
                        onAction={handleMoreOptionsMenuAction[modalType]}
                        standardButtonLabel={t(`${I18N_PREFIX}.modal.more-options.${modalType}.actionButton`)}
                        StandardButtonStyle={{
                            backgroundColor: modalType === 'disapproveOperation' ? '#e04444' : '',
                            color: '#fff',
                            border: modalType === 'disapproveOperation' ? '#e04444' : '',
                        }}
                    >
                        {modalType === 'disapproveOperation' && (
                            <SimpleTextInput
                                label={t(`${I18N_PREFIX}.modal.more-options.disapproveOperation.input.label`)}
                                placeholder={t(`${I18N_PREFIX}.modal.more-options.disapproveOperation.input.placeholder`)}
                                value={operationToDisapprove?.disapprovalReason as string}
                                onChange={disapprovalReason => handleChangeInputModal({ disapprovalReason })}
                                autoFocus
                                inputStyle={{ border: isReasonInvalid ? '1px solid red' : '' }}
                                error={isReasonInvalid}
                                errorMessage={errorMessage}
                            />
                        )}
                    </ModalMessage>
                )}
                {errorResponse && errorResponse.errors.length && (
                    <OperationApprovalModalError
                        title={t(`${I18N_PREFIX}.modal.errors.title`)}
                        errorResponse={errorResponse}
                        onClose={() => {
                            handleListOperationApprovals();
                            dispatch(approveMultiplesOperationsApprovalResetState());
                        }}
                        operationApprovals={operationApprovals?.content}
                    />
                )}
            </main>
        </>
    );
};
export default ScdOperationApproval;
