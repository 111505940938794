import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterButton from 'components/buttons/filter-button/filter-button';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import { OperationApprovalFilterRequest } from 'model/operation-approvals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';
import { OperationApprovalModalFilterType } from '../operation-approval-modal-filter/operation-approval-modal-filter';

const I18N_PREFIX = 'pages.scd.ccb-operation-approval.filter.buttons';

interface OperationApporvalFilterButtonsProps {
    setModalFilter: (value: OperationApprovalModalFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
    filterActive: OperationApprovalFilterRequest;
    handleResetFilterActive: () => void;
}

const OperationApprovalFilterButtons = (props: OperationApporvalFilterButtonsProps) => {
    const { filterActive, handleResetFilterActive, setOpenModalFilter, setModalFilter } = props;

    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const isPendingStatus = filterActive.status?.includes(OperationApprovalStatus.PENDING);

    const isActiveFilter = valuesOfObject({
        ...filterActive,
        status: isPendingStatus ? undefined : filterActive.status?.length !== 2 ? filterActive.status : undefined,
    }).some(value => value !== undefined);

    return (
        <>
            <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('filters');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isActiveFilter}
                />
            </div>
            {isPendingStatus ? (
                <>
                    <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('ccbNumber');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.ccbNumber`)}
                            icon={<ArrowDropDownIcon />}
                            isActiveFilter={!!filterActive.ccbNumber}
                        />
                    </div>
                    <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('debtorSearch');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.debtorSearch`)}
                            icon={<ArrowDropDownIcon />}
                            isActiveFilter={!!filterActive.debtorSearch}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('ccbNumber');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.ccbNumber`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.ccbNumber}
                        />
                    </div>
                    <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('status');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.status`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={filterActive.status?.length !== 2}
                        />
                    </div>
                    <div className="scd-ccb-operation-approval__header--buttons-content-filter-button">
                        <FilterButton
                            onClick={() => {
                                setModalFilter('debtorSearch');
                                setOpenModalFilter(true);
                            }}
                            label={t(`${I18N_PREFIX}.debtorSearch`)}
                            icon={arrowFilterIcon}
                            isActiveFilter={!!filterActive.debtorSearch}
                        />
                    </div>
                </>
            )}
            {isActiveFilter && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-ccb-operation-approval__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default OperationApprovalFilterButtons;
